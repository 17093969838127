<template>
  <div class="pricing">
    <vue-headful
      :title="[$t('menu.pricing')]+'- UNDERMRKT'"
      description="Description from vue-headful"
    />
    <div class="container">
      <div class="notification update-notification" v-if="planUpdated.status  == true">
        <span>🥳 Congratulations! You successfully changed Your plan.</span>
        <button-primary :onClick="function(){closeNotification()}" :class="'small secondary black'">
          Close
          <font-awesome-icon :icon="['fa','times']" />
        </button-primary>
      </div>
      <h1>
        <div class="icon">
          <font-awesome-icon :icon="['fa','dollar-sign']" />
        </div>
        {{ $t("menu.pricing") }}
      </h1>
      <div class="row">
        <div class="content">
          <div class="card">
            <div class="pricing__title">
              {{ $t("pricing.use") }}
              <strong>eBay.com</strong>
              {{ $t("pricing.like_a_pro") }}
            </div>
            <div class="pricing__description">{{ $t("pricing.choose") }}</div>
            <div class="pricing__plan-list">
              <template v-if="isLoading">
                <content-loader
                  v-for="n in 3"
                  :height="260"
                  :width="360"
                  :speed="2"
                  primaryColor="#f3f3f3"
                  secondaryColor="#e4e4e4"
                  v-bind:key="n"
                >
                  <rect x="112" y="66" rx="0" ry="0" width="0" height="0" />
                  <rect x="57" y="64" rx="0" ry="0" width="7" height="0" />
                  <rect x="9.38" y="20.61" rx="0" ry="0" width="281.89" height="43" />
                  <rect x="8.63" y="82.61" rx="0" ry="0" width="195" height="22" />
                  <rect x="7.63" y="121.61" rx="0" ry="0" width="285" height="22" />
                  <rect x="7.63" y="158.61" rx="0" ry="0" width="221" height="24" />
                  <rect x="7.63" y="222.61" rx="0" ry="0" width="281" height="26" />
                </content-loader>
              </template>
              <template v-if="!isLoading">
                <div v-for="plan in plans" class="item" v-bind:key="plan">
                  <div class="name" v-bind:style="{background:plan.color}">{{plan.name}}</div>
                  <!-- <div class="description">{{plan.description}}</div> -->
                  <div class="row">
                    <div
                      class="count"
                    >{{plan.keywords_count + plan.sellers_count}} {{ $t("pricing.items") }}</div>
                    <div class="price">
                      ${{plan.price}}
                      <span v-if="plan.price == 0">/ {{ $t("pricing.forever") }}</span>
                      <span v-else>/{{ $t("pricing.mo") }}</span>
                    </div>
                  </div>
                  <div class="list">
                    <div class="list__item">
                      <div>
                        <span>{{plan.sellers_count}}</span>
                        {{ $t("sidebar.sellers") }}
                      </div>
                      <div class="stars">
                        <font-awesome-icon
                          v-for="n in plan.sellers_count"
                          :key="n"
                          :icon="['fa','star']"
                        />
                      </div>
                    </div>
                    <div class="list__item">
                      <div>
                        <span>{{plan.keywords_count}}</span>
                        {{ $t("sidebar.keywords") }}
                      </div>
                      <div class="stars">
                        <font-awesome-icon
                          v-for="n in plan.keywords_count"
                          :key="n"
                          :icon="['fa','star']"
                        />
                      </div>
                    </div>
                    <div class="list__item">
                      <div>
                        <span>{{plan.sellers_rate}} {{ $t("global.sec") }}</span>
                        {{ $t("sidebar.update_rate") }} [{{ $t("sidebar.sellers") }}]
                      </div>
                      <font-awesome-icon :icon="['fa','check']" />
                    </div>
                    <div class="list__item">
                      <div>
                        <span>{{plan.keywords_rate}} {{ $t("global.sec") }}</span>
                        {{ $t("sidebar.update_rate") }} [{{ $t("sidebar.keywords") }}]
                      </div>
                      <font-awesome-icon :icon="['fa','check']" />
                    </div>
                    <div class="list__item">
                      <div>
                        <span>{{ $t("pricing.choose_category") }}</span>
                      </div>
                      <div v-if="plan.seller_category == true">
                        <font-awesome-icon :icon="['fa','check']" />
                      </div>
                      <div v-else class="times">
                        <font-awesome-icon :icon="['fa','times']" />
                      </div>
                    </div>
                  </div>
                  <div class="action">
                    <!-- unsubscribed user -->
                    <template v-if="plan.id != 0 && user.profile.plan == 0">
                      <button-primary
                        :onClick="function(){actionSubscribe(plan.price,plan.name, plan.id)}"
                      >{{ $t("pricing.choose_plan") }}</button-primary>
                    </template>
                    <!-- subscribed user and changing subscription to NOT free -->
                    <template
                      v-else-if="plan.id != 0 && user.profile.plan != 0 && plan.id != user.profile.plan"
                    >
                      <button-primary
                        :onClick="function(){actionUpdate(plan.price,plan.name, plan.id)}"
                      >{{ $t("pricing.choose_plan") }}</button-primary>
                    </template>
                    <!-- subscribed user and changing subscription to FREE -->
                    <template v-else-if="plan.id == 0 && user.profile.plan != 0">
                      <button-primary
                        :onClick="function(){actionUnsubscribe()}"
                      >{{ $t("pricing.choose_plan") }}</button-primary>
                    </template>
                    <!-- subscribed user and ccurrent plan -->
                    <template v-else>
                      <span class="current-plan">{{ $t("pricing.current_plan") }}</span>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="sidebar"></div>
      </div>
    </div>
    <div class="modal modal__plan" v-if="openPlanModal.status == true">
      <div class="modal__wrapper">
        <div
          class="card"
          style="border: 2px solid rgb(32, 177, 92); background: rgb(227, 255, 238);"
        >
          <div class="modal__title">
            🚀 {{ $t("pricing.changing_plan_to") }}
            <strong>"{{openPlanModal.planName}}"</strong>
          </div>
          <div class="modal__body">
            {{ $t("pricing.you_will_be_charged") }}
            <strong>${{openPlanModal.amount}}</strong>
            {{ $t("pricing.now_and_every_month") }}.
          </div>
          <div class="modal__controls">
            <div class="d-flex jsutify-content-center loader">
              <font-awesome-icon v-if="formLoading == true" :icon="['fas','circle-notch']" spin />
            </div>
            <div v-if="formLoading == false" v-html="payForm"></div>
            <button-primary
              v-if="formLoading == false"
              :onClick="function(){openPlanModal.status = false}"
              :class="'small secondary black'"
            >{{ $t("adding.cancel") }}</button-primary>
          </div>
        </div>
      </div>
    </div>
    <div class="modal modal__unsubscribe modal__plan" v-if="openUnsubscribeModal.status == true">
      <div class="modal__wrapper">
        <div class="card" style="border: 2px solid #f10707; background: #ffe3e8">
          <div class="modal__title">😔 {{ $t("pricing.downgrading_to_free") }}</div>
          <div class="modal__body">
            <div v-if="itemsToRemove.keywords > 0 || itemsToRemove.sellers > 0">
              {{ $t("pricing.before_downgrading") }}:
              <div>
                <ul>
                  <li>
                    <strong>{{itemsToRemove.sellers}}</strong>
                    {{ $t("sidebar.sellers") }}
                  </li>
                  <li>
                    <strong>{{itemsToRemove.keywords}}</strong>
                    {{ $t("sidebar.keywords") }}
                  </li>
                </ul>
              </div>
            </div>
            <div v-else>{{ $t("pricing.you_will_be_downgraded") }}</div>
          </div>
          <div class="modal__controls">
            <div
              v-if="itemsToRemove.keywords == 0 && itemsToRemove.sellers == 0"
              class="d-flex jsutify-content-center"
            >
              <button-primary :onClick="function(){confirmUnsubscribe()}" :class="'delete'">
                <span v-if="!formLoading">Downgrade</span>
                <font-awesome-icon v-if="formLoading == true" :icon="['fas','circle-notch']" spin />
              </button-primary>
            </div>
            <div v-else>
              <router-link
                :to="{name:'home'}"
                class="button button_primary"
              >{{ $t("pricing.to_list") }}</router-link>
            </div>
            <button-primary
              v-if="formLoading == false"
              :onClick="function(){openUnsubscribeModal.status = false}"
              :class="'small secondary black'"
            >{{ $t("adding.cancel") }}</button-primary>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/app";
import LiqPay from "liqpay";
import axios from "axios";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import { ContentLoader } from "vue-content-loader";
import { mapGetters } from "vuex";
import sha1 from "crypto-js/sha1";
import Base64 from "crypto-js/enc-base64";

export default {
  data() {
    return {
      plans: [],
      error: "",
      isLoading: true,
      showPayForm: false,
      payForm: "",
      unsubscribeForm: "",
      unsubscribeFormData: "",
      updateForm: "",
      updateFormData: "",
      data: "",
      formLoading: false,
      response: "",
      sendData: "",
      openUnsubscribeModal: {
        status: false
      },
      itemsToRemove: {
        keywords: 0,
        sellers: 0
      },
      openPlanModal: {
        status: false,
        planName: "name",
        amount: "0"
      },
      planUpdated: {
        status: false
      }
    };
  },
  components: {
    ContentLoader
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      plans: "plans",
      userSellers: "userSellers",
      userKeywords: "userKeywords"
    })
  },
  methods: {
    closeNotification: function() {
      this.planUpdated.status = false;
      this.$router.push({
        path: this.$route.path,
        query: { updated: "false" }
      });
      setTimeout(function() {
        window.location.reload(true);
      }, 500);
    },
    calcItemsToRemove: function(newplan) {
      var plan = newplan;

      //Keywords
      var planKeywordsCount;
      for (var i = 0; i < this.plans.length; i++) {
        if (this.plans[i].id.toString() === plan.toString()) {
          planKeywordsCount = this.plans[i].keywords_count;
        }
      }
      var countK =
        parseFloat(this.userKeywords.length) - parseFloat(planKeywordsCount);
      if (countK >= 0) {
        this.itemsToRemove.keywords = countK;
      } else {
        this.itemsToRemove.keywords = 0;
      }

      //sellers
      var planSellersCount;
      for (var x = 0; x < this.plans.length; x++) {
        if (this.plans[x].id.toString() === plan.toString()) {
          planSellersCount = this.plans[x].sellers_count;
        }
      }
      var countS =
        parseFloat(this.userSellers.length) - parseFloat(planSellersCount);
      if (countS >= 0) {
        this.itemsToRemove.sellers = countS;
      } else {
        this.itemsToRemove.sellers = 0;
      }
    },
    actionSubscribe: function(amount, name, plan) {
      this.getPayForm(amount, name, plan);
    },
    actionUpdate: function(amount, name, plan) {
      this.calcItemsToRemove(plan);
      this.getPayForm(amount, name, plan);
    },
    actionUnsubscribe: function() {
      var plan = 0;
      this.openUnsubscribeModal.status = true;
      this.calcItemsToRemove(plan);
    },
    //UNSUBSCRIBE PART
    changePlan: function(plan) {
      var params = {
        user_id: this.user.profile.user_id,
        plan: plan
      };
      params = JSON.stringify(params);
      var setPlan = firebase.functions().httpsCallable("changePlan");
      setPlan({ text: params }).then(result => {
        // var formHtml = result.data;
        // this.unsubscribeForm = formHtml;
        // this.formLoading = false;
      });
    },
    confirmUnsubscribe: function() {
      this.formLoading = true;
      this.openUnsubscribeModal.status = true;

      var order_id = this.user.profile.subscribe_order_id;
      var params = {
        order_id: order_id
      };
      params = JSON.stringify(params);
      var getForm = firebase.functions().httpsCallable("confirmUnsubscribe");
      getForm({ text: params }).then(result => {
        var formData = result;
        this.unsubscribeFormData = formData;
        if (result.data == "success") {
          this.openUnsubscribeModal.status = false;
          this.planUpdated.status = true;
          this.$router.push({ name: "home" });
        }
      });
    },
    getUpdateForm: function() {
      this.formLoading = true;

      var order_id = this.user.profile.subscribe_order_id;
      var params = {
        order_id: order_id
      };
      params = JSON.stringify(params);
      var getForm = firebase.functions().httpsCallable("getUpdateForm");
      getForm({ text: params }).then(result => {
        var formHtml = result.data;
        this.updateForm = formHtml;
        this.formLoading = false;
      });
    },
    //GET FOR FOR SUBSCRIBE
    getPayForm: function(amount, name, plan) {
      var action;
      if (parseFloat(this.user.profile.plan) > 0) {
        action = "subscribe_update";
      } else if (parseFloat(this.user.profile.plan) == 0) {
        action = "subscribe";
      }
      var order_id = this.user.profile.subscribe_order_id;
      this.formLoading = true;
      this.openPlanModal.status = true;
      var user = this.user.profile.user_id;
      var params = {
        name: name,
        amount: amount,
        user: user,
        plan: plan,
        action: action,
        order_id: order_id
      };

      this.openPlanModal.planName = name;
      this.openPlanModal.amount = amount;
      params = JSON.stringify(params);
      var getForm = firebase.functions().httpsCallable("getPayForm");
      getForm({ text: params }).then(result => {
        // var formHtml = result.data;
        var formHtml;
        if (this.$i18n.locale == "ru") {
                formHtml = result.data.replace("Pay", "Оплатить");
        } else {
                formHtml = result.data;
        }

        this.payForm = formHtml;
        this.formLoading = false;
      });
    },
    getPlans: function() {
      var db = firebase.firestore();
      db.collection("plans")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // doc.data() is never undefined for query doc snapshots
            var plan = doc.data();

            if (plan.id > -1) {
              this.plans.push(plan);
            }
            this.isLoading = false;
          });
        })
        .catch(error => {
          this.error = error;
        });
    }
  },
  beforeMount() {
    this.getPlans();
    var updated = this.$route.query.updated;
    if (updated == "true") {
      this.planUpdated.status = true;
    }
  }
};
</script>
<style lang="scss">
.update-notification {
  background: #edffe2;
  color: #007f00;
  padding: 15px;
  margin: 20px 0px 20px;
  border-radius: 6px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  border: 1px solid green;
  font-weight: bold;
  align-items: center;

  button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    background-color: #007f00 !important;
    color: #fff !important;

    svg {
      margin-left: 10px;
      margin-top: 3px;
    }
  }
}

.current-plan {
  display: inline-flex;
  height: 48px;
  align-items: center;
  font-weight: bold;
  border: 1px solid #e7e7e7;
  width: 100%;
  justify-content: center;
  padding: 0px 10px;
  color: #666;
}

.modal__plan {
  .modal__body {
    line-height: 1.4;
  }

  .modal__controls {
    flex-direction: column;

    .loader {
      justify-content: center;

      svg {
        fill: #38bb38 !important;
        height: 48px;
        width: 48px;
        min-width: 48px;

        * {
          fill: #38bb38 !important;
        }
      }

      .delete {
        svg {
        }
      }
    }

    .button,
    input[type="submit"] {
      width: 100% !important;
      min-width: 100% !important;
      margin: 5px 0px;

      &.button_primary {
        height: 48px !important;
        background-color: #38bb38;
            box-sizing: border-box;

        &:hover {
          background-color: lighten(#38bb38, 10%);
        }
      }
    }
  }
}

.pricing {
  &__plan-list {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;

    //align-items:baseline;
    svg {
      min-width: 33%;
    }

    .item {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 15px;
      margin: 10px;
      box-shadow: 0px 0px 12px 0px rgba(#000, 0.1);
      border-radius: 6px;
      box-sizing: border-box;
      @media (max-width: 991px) {
        min-width: calc(50% - 30px);
        max-width: calc(50% - 30px);
      }
      @media (max-width: 991px) {
        min-width: 320px;
        max-width: 320px;
      }
      .button {
        height: 48px !important;
        text-transform: uppercase;
        letter-spacing: 1px;
        background-color: #38bb38;

        &:hover {
          background-color: lighten(#38bb38, 10%);
        }
      }

      .name {
        font-size: 32px;
        font-weight: 300;
        margin-bottom: 20px;
        color: #fff;
        text-align: center;
        margin: -15px;
        margin-bottom: 20px;
        padding: 15px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      .description {
        font-size: 14px;
        margin-bottom: 20px;
        color: #666;
        text-align: center;
      }

      .price {
        font-size: 32px;
        font-weight: 500;
        text-align: right;
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          color: #666;
          font-weight: 300;
          margin-left: 5px;
          text-transform: uppercase;
        }
      }

      .count {
        font-weight: 500;
      }

      .action {
        display: flex;
        justify-content: center;
        padding: 15px 0px;

        button {
          height: 36px;
          width: 100%;
        }
      }

      .row {
        padding: 10px 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__description {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    font-size: 300;
    color: #ccc;
  }

  &__title {
    text-align: center;
    padding-top: 30px;
    font-size: 36px;
    margin-bottom: 10px;
  }
}

.list {
  margin: 20px 0px;

  &__item {
    display: flex;
    justify-content: space-between;
    color: #666;
    align-items: center;
    padding: 10px 8px;
    border-radius: 6px;

    &:nth-child(2n - 1) {
      background-color: #f7f7f7;
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: #000;
    }

    .stars {
      svg {
        width: 14px;
        min-width: 14px;

        * {
          fill: #e6bb31 !important;
        }
      }
    }

    .times {
      svg {
        height: 20px;
        width: 20px;
        min-width: 20px;

        * {
          fill: #f00 !important;
        }
      }
    }

    svg {
      height: 20px;
      width: 20px;
      min-width: 20px;
      margin-right: 3px;

      * {
        fill: #5ed45e !important;
      }
    }
  }
}

.icon {
  background-color: #5ed45e !important;
}

.content {
  width: 100%;
}
</style>